<template>
  <div style="margin:0; padding:0">
    <PageHeader :subtitle="subtitle" :title="title" />

    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row">
              <div class="col-sm-2" v-if="showFirmFilter">
                <FirmFilter v-on:firm_change="filterByFirm"  />
              </div>
              <div class="col-sm-3">
                <DistrictFilter v-on:district_change="filterByDistrict" />
              </div>
              <div class="col-sm-4" v-if="showCoordinatorFilter">
                <CoordinatorFilter v-on:coordinator_change="filterByCoordinator" />
              </div>
              <div class="col-sm-4" v-if="showSupervisorFilter">
                <SupervisorFilter v-on:supervisor_change="filterBySupervisor" />
              </div>
              <div class="col-sm-3">
                <div class="btn-group float-right" data-toggle="buttons" aria-label>
                  <el-input
                    v-model="tableFilteredData[0].value"
                    placeholder="Search"
                    prefix-icon="el-icon-search"
                    size="medium"
                    style="width: 250px"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <data-tables-server
              :loading="busy"
              :data="tableData"
              :total="tableTotal"
              :filters="tableFilteredData"
              @query-change="get"
              :table-props="tableProps"
            >
              <el-table-column
                v-for="(item, idx) in visibleFields"
                :key="idx"
                :prop="item.prop"
                :label="item.label"
                :sortable="item.sortable"
                :width="item.width">
                <template slot-scope="scope">
                  <span v-if="item.prop === 'performance'">
                    <b-badge v-if="scope.row[item.prop] < cutoff" variant="danger">{{ scope.row[item.prop] }}%</b-badge>
                    <b-badge v-else variant="success">{{ scope.row[item.prop] }}%</b-badge>
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import { DataTablesServer } from 'vue-data-tables'
let numeral = require('numeral')

import apiService from '../api/user'

import PageHeader from '../containers/PageHeader'
import SupervisorFilter from '../components/filters/SupervisorFilter'
import CoordinatorFilter from '../components/filters/CoordinatorFilter'
import DistrictFilter from '../components/filters/DistrictFilter'
import FirmFilter from '../components/filters/FirmFilter'

Vue.use(DataTablesServer)
Vue.use(require('vue-moment'))

export default {
  name: 'UserPerformance',
  props: {
    type: { type: String, default: 'enumerator' },
    subtitle: { type: String, default: '' },
    actionCol: { type: Boolean, default: false }
  },
  components: {
    PageHeader,
    FirmFilter,
    DistrictFilter,
    CoordinatorFilter,
    SupervisorFilter
  },
  data() {
    return {
      busy: true,
      cutoff: 80,
      sortProps: { order: 'ascending', prop: 'fullname' },
      rawData: [],
      tableData: [],
      tableTotal: 0,
      tableFields: [],
      tableFilteredData: [
        {
          value: '',
          prop: ['fullname', 'supervisor', 'qa_status', 'score']
        }
      ],
      tableProps: {
        style: 'width: 99%',
        emptyText: 'No responses found',
        stripe: true,
        defaultSort: this.sortProps
      },
      viewIgnoreFields: [
        'supervisor',
        'enumeration_count',
        'performance',
        'enumeration_c'
      ],
      sFilter: '',
      firmFilter: '',
      districtFilter: '',
      coordinatorFilter: '',
      queryProps: {
        page: 1,
        pageSize: 10,
        sort: this.sortProps,
        search: '',
        qa_status: '',
        supervisor: '',
        coodinator: '',
        district: '',
        firm: ''
      }
    }
  },
  created() {
    this.firmFilter = this.isGnhr ? 0 : this.user.firm_id
    this.setTableFields()
    this.get()
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isGnhr: 'isGnhr'
    }),
    showFirmFilter: function () {
      return this.isGnhr
    },
    showCoordinatorFilter: function () {
      return this.type === 'supervisor'
    },
    showSupervisorFilter: function () {
      return this.type === 'enumerator'
    },
    visibleFields: function() {
      return this.tableFields.filter(function(f) {
        return f.visible
      })
    },
    title: function() {
      let prefix = (this.type === 'supervisor') ? 'Supervisors (' : ((this.type === 'enumerator') ? 'Enumerators (' : 'Coordinators (')
      return prefix + numeral(this.tableTotal).format('0,0') + ')'
    }
  },
  methods: {
    setTableFields () {
      if (this.isGnhr) {
        this.tableFields.push({ prop: 'firm', label: 'Firm', visible: true, sortable: true, width: '100' })
      }

      this.tableFields.push({ prop: 'district', label: 'District', visible: true, sortable: true, width: '200' })

      let targetLabel = 'Group Target'
      if (this.type === 'enumerator') {
        targetLabel = 'Target'
        this.tableFields.push({ prop: 'supervisor', label: 'Supervisor', visible: true, sortable: true, width: '200' })
      } else if (this.type === 'supervisor') {
        this.tableFields.push({ prop: 'coordinator', label: 'Coordinator', visible: true, sortable: true, width: '200' })
      } else {
        targetLabel = 'District Target'
      }


      this.tableFields.push({ prop: 'linkId', label: 'ID', visible: false, sortable: 'custom', width: '120' })
      this.tableFields.push({ prop: 'fullname', label: 'Name', visible: true, sortable: true, width: '200' })
      this.tableFields.push({ prop: 'enumeration_expected', label: targetLabel, visible: true, sortable: false, numeric: true, width: '150' })
      this.tableFields.push({ prop: 'enumeration_actual', label: 'HHs Enumerated', visible: true, sortable: false, numeric: true, width: '90' })
      this.tableFields.push({ prop: 'members_count', label: 'Members Enumerated', visible: true, sortable: false, numeric: true, width: '90' })
      this.tableFields.push({ prop: 'error_count', label: 'Errors', visible: true, sortable: false, width: '90' })
      this.tableFields.push({ prop: 'performance', label: 'Performance (%)', visible: true, sortable: false })
    },
    get(queryInfo = {}) {
      let self = this
      this.busy = true
      this.queryProps = queryInfo
      let params = this.getParams(queryInfo)
      if (this.type === 'enumerator') {
        apiService.getEnumerators(params).then(response => self.loadTable(response) ).catch(error => {
          this.busy = false
          this.handleError(error)
        })
      } else if (this.type === 'supervisor') {
        apiService.getSupervisors(params).then(response => self.loadTable(response) ).catch(error => {
          this.busy = false
          this.handleError(error)
        })
      } else {
        apiService.getCoordinators(params).then(response => self.loadTable(response) ).catch(error => {
          this.busy = false
          this.handleError(error)
        })
      }
    },
    loadTable (response) {
      let self = this
      self.rawData = response.data
      self.tableData = response.data.map(function(s) {
        return self.getSimpleRow(s)
      })
      self.tableTotal = response.meta.total
      self.busy = false
    },
    filterByFirm (v) {
      this.firmFilter = v.value
      this.get(this.queryProps)
    },
    filterByDistrict(v) {
      this.districtFilter = v.value
      this.get(this.queryProps)
    },
    filterByCoordinator (v) {
      this.coordinatorFilter = v.value
      this.get(this.queryProps)
    },
    filterBySupervisor(v) {
      this.sFilter = v.value
      this.get(this.queryProps)
    },
    getParams(info) {
      return {
        page: info.page,
        limit: info.pageSize,
        sort_field:
          is.not.undefined(info.sort) &&
          is.not.undefined(info.sort.prop) &&
          is.not.empty(info.sort.prop)
            ? info.sort.prop
            : this.sortProps.prop,
        sort_order:
          is.not.undefined(info.sort) &&
          is.not.undefined(info.sort.order) &&
          is.not.empty(info.sort.order)
            ? info.sort.order
            : this.sortProps.order,
        search:
          info.type === 'filter'
            ? is.not.undefined(info.filters[0])
              ? info.filters[0]['value']
              : ''
            : '',
        firm: this.firmFilter === 0 ? '' : this.firmFilter,
        district: this.districtFilter === 0 ? '' : this.districtFilter,
        coordinator: this.coordinatorFilter === 0 ? '' : this.coordinatorFilter,
        supervisor: this.sFilter === 0 ? '' : this.sFilter
      }
    },
    getSimpleRow(item) {
      let x = {}
      for (let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date)
          ? this.$moment(item[i.prop]).format('MMM Do, YYYY')
          : item[i.prop]
        x[i.prop] = is.existy(i.numeric)
          ? numeral(item[i.prop]).format('0,0')
          : x[i.prop]
      }
      return x
    }
  }
}
</script>
<style>
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
</style>
