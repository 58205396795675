<template>
    <b-form-group
      id="coordinator_combo"
      label="Coordinator "
      label-class="font-weight-bolder"
      :label-cols="lpos"
      label-for="coordinator_select"
      :state="null">
      <model-list-select
        id="coordinator_select"
        name="coordinator_select"
        :list="coordinators"
        v-model="selected"
        placeholder="Select Coordinator"
        option-value="id"
        option-text="label"
      ></model-list-select>
    </b-form-group>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'CoordinatorFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number, default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    first: true,
    selected: ''
  }),
  computed: {
    ...mapGetters({
      coordinators: 'getCoordinatorDropdown'
    }),
    lpos: function() {
      return this.labelPosition === 'left' ? 'auto' : 0
    }
  },
  watch: {
    selected: {
      handler: function() {
        this.sendUpdate()
      },
      deep: true
    }
  },
  created () {
    this.selected = this.id
  },
  methods: {
    sendUpdate() {
      if (!this.first) {
        this.$emit('coordinator_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
