<template>
  <div style="margin:0; padding:0">
    <UserPerformance type="supervisor" subtitle="Manage Supervisors" />
  </div>
</template>

<script>
import UserPerformance from '../../components/UserPerformance'

export default {
  name: 'Supervisors',
  components: {
    UserPerformance
  }
}
</script>
<style>
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
</style>