<template lang="pug">
    extends ../BaseView.pug
</template>

<script>
import Vue from 'vue'
import BaseView from '../BaseView'

import Enumerators from './Enumerators'
import Supervisors from './Supervisors'
import Coordinators from './Coordinators'

Vue.component('enumerators', { template: '<Enumerators />' })
Vue.component('supervisors', { template: '<Supervisors />' })
Vue.component('coordinators', { template: '<Coordinators />' })



export default {
  name: 'Enumerations',
  extends: BaseView,
  components: { Enumerators, Supervisors, Coordinators },
  props: {
    pages: {
      type: Array,
      default: () => {
        return [
          {
            tabName: 'enumerators',
            component: 'enumerators'
          },
          {
            tabName: 'supervisors',
            component: 'supervisors'
          },
          {
            tabName: 'coordinators',
            component: 'coordinators'
          }
        ]
      }
    }
  }
}
</script>
